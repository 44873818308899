import { createStitches } from '@stitches/react';

const stitches = createStitches({
  theme: {
    colors: {
      primary: '#bca4ff',
      primaryLlight: '#f1e4ff',
      base: '#f395ba',
      secondary: '#1cc8ee',
      secondary_darkmode: '#82e9ff',
      tertiary: '#ff84b7',
      tertiary_darkmode: '#ff84b7',
      warning: '#ffd789',
      warning_darkmode: '#ffd789',
      success: '#c1e5c0',
      succes_darkmode: '#34eab9',
      bruner: '#C6E3EA',
      rose: '#fdd9d9',
      light1: '#fcfcfc',
      light2: '#f7f7fc',
      light3: '#eff0f7',
      light4: '#d9dbe9',
      light5: '#e4e7f1',
      dark1: '#161b22',
      dark2: '#21262d',
      dark3: '#30363d',
      dark4: '#42474d',
      dark5: '#6e7681',
      background: '$light1',
      backgroundInvert: '$dark1',
      text: '$dark1',
      // Semantic colors
      hiContrast: '$slate12',
      loContrast: '$slate1',
      canvas: 'hsl(0 0% 93%)',
      panel: 'white',
      transparentPanel: 'hsl(0 0% 0% / 97%)',
      shadowLight: 'hsl(206 22% 7% / 35%)',
      shadowDark: 'hsl(206 22% 7% / 20%)',
    },
    fonts: {
      body: "'Montserrat', sans-serif",
      heading: 'inherit',
      monospace: 'Menlo, monospace',
    },
    fontSizes: {
      1: '11px',
      2: '13px',
      3: '16px',
      4: '18px',
      5: '21px',
      6: '24px',
      7: '32px',
      8: '43px',
      9: '50px',
      10: '64px',
      11: '101px',
      12: '135px',
    },
    fontWeights: {
      1: '100',
      2: '200',
      3: '300',
      4: '400',
      5: '500',
      6: '600',
      7: '700',
      8: '800',
      9: '900',
      normal: '$4',
      bold: '$7',
      extraBold: '$9',
    },
    lineHeights: {
      1: '18px',
      2: '21px',
      3: '24px',
      4: '26px',
      5: '29px',
      6: '32px',
      7: '40px',
      8: '50px',
      9: '60px',
      10: '76px',
      11: '101px',
      12: '125px',
    },
    shadows: {
      small: '0 0 4px rgba(0, 0, 0, .125)',
      md: '0 0 12px rgba(0, 0, 0, .125)',
      large: '0 0 24px rgba(0, 0, 0, .125)',
      xl: ' 0px 30px 40px rgba(0, 0, 0, .125)',
    },
    space: {
      0: '0px',
      1: '4px',
      2: '8px',
      3: '16px',
      4: '22px',
      5: '32px',
      6: '64px',
      7: '120px',
      8: '256px',
    },
    sizes: {
      0: '0px',
      5: '30px',
      xxs: '300px',
      xs: '480px',
      sm: '540px',
      md: '728px',
      lg: '940px',
      xl: '1200px',
      xxl: '1600px',
    },

    radii: {
      1: '4px',
      2: '6px',
      3: '8px',
      4: '0.8rem',
      round: '50%',
      pill: '9999px',
    },
    zIndices: {
      1: '100',
      2: '200',
      3: '300',
      4: '400',
      max: '999',
    },
  },
  media: {
    xxs: '(max-width: 320px)',
    xs: '(max-width: 480)',
    sm: '(max-width: 540px)',
    md: '(max-width: 728px)',
    lg: '(max-width: 940px)',
    xl: '(max-width: 1200px)',
    xxl: '(max-width: 1600px)',
    motion: '(prefers-reduced-motion)',
    hover: '(any-hover: hover)',
    dark: '(prefers-color-scheme: dark)',
    light: '(prefers-color-scheme: light)',
  },
  utils: {
    p: (value) => ({
      paddingTop: value,
      paddingBottom: value,
      paddingLeft: value,
      paddingRight: value,
    }),
    pt: (value) => ({
      paddingTop: value,
    }),
    pr: (value) => ({
      paddingRight: value,
    }),
    pb: (value) => ({
      paddingBottom: value,
    }),
    pl: (value) => ({
      paddingLeft: value,
    }),
    px: (value) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    m: (value) => ({
      marginTop: value,
      marginBottom: value,
      marginLeft: value,
      marginRight: value,
    }),
    mt: (value) => ({
      marginTop: value,
    }),
    mr: (value) => ({
      marginRight: value,
    }),
    mb: (value) => ({
      marginBottom: value,
    }),
    ml: (value) => ({
      marginLeft: value,
    }),
    mx: (value) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value) => ({
      marginTop: value,
      marginBottom: value,
    }),

    ta: (value) => ({ textAlign: value }),

    fd: (value) => ({ flexDirection: value }),
    fw: (value) => ({ flexWrap: value }),

    ai: (value) => ({ alignItems: value }),
    ac: (value) => ({ alignContent: value }),
    ji: (value) => ({ justifyItems: value }),
    jc: (value) => ({ justifyContent: value }),
    as: (value) => ({ alignSelf: value }),
    fg: (value) => ({ flexGrow: value }),
    fs: (value) => ({ fontSize: value }),
    fb: (value) => ({ flexBasis: value }),

    bg: (value) => ({
      backgroundColor: value,
    }),
    stopColor: (value) => ({
      stopColor: value,
    }),
    bb: (value) => ({
      borderBottom: value,
    }),

    br: (value) => ({
      borderRadius: value,
    }),
    btrr: (value) => ({
      borderTopRightRadius: value,
    }),
    bbrr: (value) => ({
      borderBottomRightRadius: value,
    }),
    bblr: (value) => ({
      borderBottomLeftRadius: value,
    }),
    btlr: (value) => ({
      borderTopLeftRadius: value,
    }),

    bs: (value) => ({ boxShadow: value }),

    lh: (value) => ({ lineHeight: value }),

    ox: (value) => ({ overflowX: value }),
    oy: (value) => ({ overflowY: value }),

    pe: (value) => ({ pointerEvents: value }),
    us: (value) => ({ WebkitUserSelect: value, userSelect: value }),

    size: (value) => ({
      width: value,
      height: value,
    }),

    linearGradient: (value) => ({
      backgroundImage: `linear-gradient(to bottom right,${value}, rgba(37,99,235,0))`,
    }),

    appearance: (value) => ({
      WebkitAppearance: value,
      appearance: value,
    }),
    userSelect: (value) => ({
      WebkitUserSelect: value,
      userSelect: value,
    }),
    backgroundClip: (value) => ({
      WebkitBackgroundClip: value,
      backgroundClip: value,
    }),
  },
});

export const breakpoints = {
  default: (rule) => rule,
  xxs: (rule) => `@media (max-width: 320px) { ${rule} }`,
  xs: (rule) => `@media (max-width: 480) { ${rule} }`,
  sm: (rule) => `@media (max-width: 540px) { ${rule} }`,
  md: (rule) => `@media (max-width: 728px) { ${rule} }`,
  lg: (rule) => `@media (max-width: 940) { ${rule} }`,
  xl: (rule) => `@media (max-width: 1200px) { ${rule} }`,
  xxl: (rule) => `@media (max-width: 1600px) { ${rule} }`,
  motion: (rule) => `@media (prefers-reduced-motion) { ${rule} }`,
  hover: (rule) => `@media (hover: hover) { ${rule} }`,
  dark: (rule) => `@media (prefers-color-scheme: dark) { ${rule} }`,
  light: (rule) => `@media (prefers-color-scheme: light) { ${rule} }`,
};

export const {
  styled,
  css,
  globalCss,
  keyframes,
  theme,
  getCssText,
  createTheme,
} = stitches;

export const darkTheme = createTheme('dark', {
  colors: {
    primary: 'lightpink',
    primaryLight: 'lightpink',
    tertiary: 'cornflowerblue',
    base: '$secondary_darkmode',
    background: '$dark1',
    backgroundInvert: '$light1',
    text: '$light1',
    success: '$warning_darkmode',
    warning: '$tertiary_darkmode',
    secondary: '$succes_darkmode',
    light2: '$dark2',
    light3: '$dark3',
    light4: '$dark4',
    light5: '$dark5',
    shadow: '0px 2px 2px rgb(0 0 0 /30%)',

    // Semantic colors
    hiContrast: '$slate12',
    loContrast: '$slate1',
    canvas: 'hsl(0 0% 15%)',
    panel: '$slate3',
    transparentPanel: 'hsl(0 100% 100% / 97%)',
    shadowLight: 'hsl(206 22% 7% / 35%)',
    shadowDark: 'hsl(206 22% 7% / 20%)',
  },
});
