import { globalCss } from '../../stitches.config';

export const globalStyles = globalCss({
  'html,body': {
    margin: '0',
    padding: '0',
    fontFamily: 'poppins',
  //  fontSize: "2.1rem"
  },
  '*': {
    boxSizing: 'inherit',
    '&::before,&::after': { boxSizing: 'inherit' },
    '&::-moz-focus-inner': { border: '0' },
  },
  h1: { fontSize: '$10', lineHeight: '$10' },
  h2: { fontSize: '$9', lineHeight: '$9' },
  h3: { fontSize: '$7', lineHeight: '$6' },
  h4: { fontSize: '$6', fontWeight: '$5', lineHeight: '$5' },
  h5: { fontWeight: '$5' },
  'h6,ul,ol': { fontWeight: '$4' },
  'p,li, a': { fontSize: '$3', lineHeight: '$4' },
  pre: {
    borderRadius: 'var(--radius)',
    position: 'relative',
    whiteSpace: 'pre',
    fontSize: '$3',
  },
  'h1,h2,h3,h4,h5,h6': { color: '$text' },
  'h3,h4,p,ul,li,input,select,textarea,button': {
    lineHeight: '32px',
  },
  '.lamplight': { transition: 'opacity 0.8s' },
  '.gameboyLight': { transition: 'fill 0.8s' },
});
